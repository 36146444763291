<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-btn icon>
        <v-icon>mdi-database-import</v-icon>
      </v-btn>

      <v-toolbar-title>Änderungen</v-toolbar-title>

      <v-spacer />

      <v-text-field
        class="mr-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
        clearable
        id="search"
      ></v-text-field>

      <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="error"></v-tabs-slider>
          <v-tab :to="{ name: 'ChangesFetch' }"> Suchen </v-tab>
          <v-tab :to="{ name: 'ChangesView' }"> Änderungen </v-tab>
          <v-tab :to="{ name: 'ChangesExecute' }"> Ausführen </v-tab>
          <v-tab :to="{ name: 'ChangesLock' }"> Sperren </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <router-view :search="search"></router-view>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Changes",
  data: () => ({
    tab: 0,
    search: "",
  }),
});
</script>

<style>
tr.locked {
  background-color: lightgrey !important;
}
tr.accepted {
  background-color: #74bf3d !important;
}
</style>
